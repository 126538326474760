import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/Seo/Seo";
import Modules from "../components/Modules/Modules";

const PageTemplate = (props) => {
  const post = props.data.post;
  useEffect(() => {
    document.body.classList.remove("mega-menu-open");
    document.body.classList.remove("booking-open");
    document.body.classList.remove("form-enquiry-open");
    document.body.classList.remove("menu-open");

    if (props.location.pathname === "/agent-zone/") {
      document.body.classList.add("instagram-hide");
      if (props.location.pathname === "/agent-zone/") {
        document.body.classList.add("insta-hide-bottom");
      }
    } else if (props.location.pathname === "/thank-you") {
      document.body.classList.add("instagram-hide");
      if (props.location.pathname === "/thank-you") {
        document.body.classList.add("thankyou-hide-bottom");
      }
    } else {
      document.body.classList.remove("instagram-hide");
      document.body.classList.remove("insta-hide-bottom");
      document.body.classList.remove("thankyou-hide-bottom");
    }
  });
  return (
    <Layout>
      <Seo seo={post.seo} seoData={post.AcfPageContent.seoData}/>
      <Modules modules={post.AcfPageContent} />
    </Layout>
  );
};

export default PageTemplate;
export const pageQuery = graphql/* GraphQL */ `
  query PageById($id: String!) {
    post: wpPage(id: { eq: $id }) {
      id
      title
      ...ModulesFragment
      ...seoPageFragment
    }
  }
`;
